import clsx from 'clsx'
import React from 'react'
import styles from './styles.module.scss'

export const ButtonGroupItem = ({
  active = false,
  children,
  className = '',
  ...rest
}) => {
  return (
    <div
      {...rest}
      className={clsx(
        styles.buttonGroupItem,
        { [styles.active]: active },
        className
      )}
    >
      {children}
    </div>
  )
}

export default ButtonGroupItem
