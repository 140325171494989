import * as d3Selection from 'd3-selection'
import * as d3Zoom from 'd3-zoom'
import { parseUrl } from 'src/utils/url'

export const ZOOM_STEP = 25
export const ZOOM_IN_MIN = 1
export const ZOOM_IN_MAX = 10000

export class ZoomBehavior {
  constructor(url) {
    const zoomFromQuery = parseUrl('zoom', url)

    this.transform = {
      x: 0,
      y: 0,
      k: ZOOM_IN_MIN,
      ...zoomFromQuery,
    }

    this.handleZoom = this.handleZoom.bind(this)
    this.filterOrphanedEvents = this.filterOrphanedEvents.bind(this)
  }

  touchTimer = null

  /* d3-zoom not fired touchend event, and next event work like multi-touch
   * @see https://github.com/d3/d3-zoom/issues/120
   * this.filterOrphanedEvents cant catch that, workaround is re-create zoom
   * */
  restartTouchEndTimer = ({ rootNode, onZoom }) => {
    this.touchTimer && clearTimeout(this.touchTimer)
    this.touchTimer = setTimeout(() => {
      this.d3Root.on('.zoom', null)
      this.create({ rootNode, onZoom })
    }, 100)
  }

  create({ rootNode, onZoom, width, height } = {}) {
    this.rootNode = rootNode
    this.onZoom = onZoom
    this.d3Root = d3Selection.select(this.rootNode)
    this.zoom = d3Zoom
      .zoom()
      .scaleExtent([ZOOM_IN_MIN, ZOOM_IN_MAX])
      .translateExtent([
        [0, 0],
        [width || this.width, height || this.height],
      ])
      .extent([
        [0, 0],
        [width || this.width, height || this.height],
      ])
      .filter(this.filterOrphanedEvents)
      .on('zoom', () => {
        this.handleZoom.call(this)
        this.restartTouchEndTimer({ rootNode, onZoom })
      })
      .on('start', () => {
        this.onStart()
        this.restartTouchEndTimer({ rootNode, onZoom })
      })
      .on('end', () => {
        this.onEnd()
        this.touchTimer && clearTimeout(this.touchTimer)
      })

    this.d3Root.call(this.zoom).on('dblclick.zoom', null)
  }

  setProps({ width, height, url } = {}) {
    const zoomFromQuery = parseUrl('zoom', url)
    this.width = width
    this.height = height
    const { k, x, y } = zoomFromQuery
    if (k || x || y) {
      this.transform.k = k || this.transform.k
      this.transform.x = x || this.transform.x
      this.transform.y = y || this.transform.y
    }
  }

  filterOrphanedEvents() {
    if (
      d3Selection.event.type === 'touchmove' &&
      this.transform.x === 0 &&
      this.transform.y === 0 &&
      d3Selection.event.touches.length === 1
    ) {
      this.d3Root.on('.zoom', null)
      setTimeout(() => {
        this.d3Root.call(this.zoom).on('dblclick.zoom', null)
      }, 100)
      return false
    }
    return true
  }

  handleZoom() {
    this.prevTransform = this.transform
    const newTransform = d3Selection.event.transform

    if (
      (this.prevTransform.k === ZOOM_IN_MIN &&
        newTransform.k === ZOOM_IN_MIN) ||
      (this.prevTransform.k === ZOOM_IN_MAX && newTransform.k === ZOOM_IN_MAX)
    ) {
      return
    }

    this.transform = newTransform
    this.onZoom(this.transform)
  }

  zoomIn() {
    this.zoom.scaleTo(
      this.d3Root,
      this.transform.k + ZOOM_STEP / 25 > ZOOM_IN_MAX
        ? ZOOM_IN_MAX
        : this.transform.k +
            (this.transform.k > 10 ? ZOOM_STEP : ZOOM_STEP / 25)
    )
  }

  zoomOut() {
    this.zoom.scaleTo(
      this.d3Root,
      this.transform.k - ZOOM_STEP / 25 < ZOOM_IN_MIN
        ? ZOOM_IN_MIN
        : this.transform.k -
            (this.transform.k - 1 > 10 ? ZOOM_STEP : ZOOM_STEP / 25)
    )
  }

  reset() {
    if (!this.zoom) return // when user's first page was not the one with treemap

    this.transform = {
      x: 0,
      y: 0,
      k: ZOOM_IN_MIN,
    }
    if (this.onZoom) {
      this.onZoom(this.transform)
    }
    this.zoom.scaleTo(this.d3Root, ZOOM_IN_MIN)
  }
}

export default new ZoomBehavior()
