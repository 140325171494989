import React from 'react'
import { SHARE_DATA_TYPES } from 'src/consts'
import format from 'date-fns/format'
import { ShareButtons } from 'react-share'
import styles from './styles.module.scss'
import { combinedAnalytics } from 'src/utils/common'

const {
  WhatsappShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookShareButton,
  TwitterShareButton,
} = ShareButtons

const longTimeDate = () => format(new Date(), 'HH:mm, ddd, dd MMM yyyy')

export type SocialProps = {
  description: string
  linkImg: string
  linkShare: string
  shareType: ValueOf<typeof SHARE_DATA_TYPES>
  title: string
}

export function Social({
  description,
  linkImg,
  linkShare,
  shareType,
  title,
}: SocialProps) {
  const sharingText = shareType === SHARE_DATA_TYPES.LINK ? description : title
  const sharingTitle = title

  const sharingDescription =
    {
      [SHARE_DATA_TYPES.LINK]: description,
    }[shareType] ||
    `Сryptocurrency Visualizations Map. ${title} ${longTimeDate()}`

  const sharingImage =
    {
      [SHARE_DATA_TYPES.LINK]: linkImg,
    }[shareType] ||
    linkShare
      .replace('share', 'shareimg')
      .concat('.png', `?i=${Math.floor(Math.random() * 1e3)}`)

  const handleShareInteraction = (label, type = 'Click') => {
    combinedAnalytics(
      `Share ${shareType.toLowerCase()}`,
      `Share ${shareType.toLowerCase()}`,
      'Interaction',
      `${type} ${label}`
    )
  }

  return (
    <div className={styles.social}>
      <TwitterShareButton
        className={styles.socialButton}
        onClick={() => handleShareInteraction('twitter')}
        title={sharingText}
        url={linkShare}
      >
        <span
          className={styles.icon}
          style={{
            backgroundImage: "url('/assets/social/icon-twitter-white.svg')",
          }}
        />
        Tweet
      </TwitterShareButton>
      <FacebookShareButton
        className={styles.socialButton}
        description={sharingDescription}
        onClick={() => handleShareInteraction('facebook')}
        picture={sharingImage}
        quote={sharingTitle}
        url={`${linkShare}?i=${Math.floor(Math.random() * 1e3)}`}
      >
        <span
          className={styles.icon}
          style={{
            backgroundImage: "url('/assets/social/icon-facebook-white.svg')",
          }}
        />
        Share
      </FacebookShareButton>
      <TelegramShareButton
        className={styles.socialButton}
        onClick={() => handleShareInteraction('telegram')}
        title={sharingText}
        url={linkShare}
      >
        <span
          className={styles.icon}
          style={{
            backgroundImage: "url('/assets/social/icon-telegram-white.svg')",
          }}
        />
        Telegram
      </TelegramShareButton>
      <RedditShareButton
        className={styles.socialButton}
        onClick={() => handleShareInteraction('reddit')}
        title={sharingText}
        url={linkShare}
      >
        <span
          className={styles.icon}
          style={{ backgroundImage: "url('/assets/social/icon-reddit.svg')" }}
        />{' '}
        Reddit
      </RedditShareButton>
      <WhatsappShareButton
        className={styles.socialButton}
        onClick={() => handleShareInteraction('whatsapp')}
        title={sharingText}
        url={linkShare}
      >
        <span
          className={styles.icon}
          style={{ backgroundImage: "url('/assets/social/icon-whatsapp.svg')" }}
        />
        WhatsApp
      </WhatsappShareButton>
      <a
        className={styles.socialButton}
        download='Coin360.png'
        href={linkImg}
        onClick={() => handleShareInteraction('download')}
        rel='noopener noreferrer'
        target='_blank'
      >
        <span
          className={styles.icon}
          style={{ backgroundImage: "url('/assets/icons/icon-download.svg')" }}
        />
        Download
      </a>
    </div>
  )
}

export default Social
