import styles from './styles.module.scss'
import clsx from 'clsx'

interface Props {
  className?: string
  [key: string]: any
}

export const InnerLoader = ({ className, ...rest }: Props) => {
  return (
    <div className={clsx(styles.innerLoader, className)} {...rest}>
      <div className={styles.wrapper}>
        <div className={styles.gray1} />
        <div className={styles.gray2} />
        <div className={styles.gray3} />
      </div>
      <div className={styles.gray_brand} />
    </div>
  )
}

export default InnerLoader
