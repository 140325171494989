import { Component } from 'react'
import { ShareDialog } from './components/ShareDialog'

type SharePageProps = {
  onClose: () => void
  open: boolean
}

export class SharePage extends Component<SharePageProps> {
  state = {
    error: false,
  }

  componentDidCatch() {
    this.setState({ error: true })
  }

  render() {
    const { open, onClose } = this.props
    const { error } = this.state

    if (!open || error) return null

    return <ShareDialog open={open} onClose={onClose} />
  }
}

export default SharePage
