export const requestFullScreen = () => {
  const documentElement = document.documentElement
  const request =
    (documentElement.requestFullscreen &&
      documentElement.requestFullscreen.bind(documentElement)) ||
    (documentElement.mozRequestFullScreen &&
      documentElement.mozRequestFullScreen.bind(documentElement)) ||
    (documentElement.webkitRequestFullscreen &&
      documentElement.webkitRequestFullscreen.bind(documentElement))
  request()
}

export const cancelFullScreen = () => {
  const cancel =
    (document.cancelFullscreen && document.cancelFullScreen.bind(document)) ||
    (document.mozCancelFullScreen &&
      document.mozCancelFullScreen.bind(document)) ||
    (document.webkitCancelFullScreen &&
      document.webkitCancelFullScreen.bind(document))
  cancel()
}
