import clsx from 'clsx'
import { Children, cloneElement } from 'react'
import styles from './styles.module.scss'
export { ButtonGroupItem } from './components/ButtonGroupItem'

export const ButtonGroup = ({ className, children, onChange, value }) => {
  return (
    <div className={clsx(styles.buttonGroup, className)}>
      {Children.map(children, child => {
        if (child) {
          return cloneElement(child, {
            active: child.props.value === value,
            onClick: () => onChange(child.props.value),
          })
        }
      })}
    </div>
  )
}

export default ButtonGroup
