/**
 * Copies text to Clipboard. For old browsers fallbacks to a working hack
 * @param {*} text the text that you want to copy
 * @returns {boolean}
 */
export const copyToClipboard = async text => {
  if (!navigator.clipboard) {
    return oldStyleCopy(text)
  }

  try {
    await navigator.clipboard.writeText(text)
    return true
  } catch (err) {
    return oldStyleCopy(text)
  }
}

const oldStyleCopy = text => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  let result

  try {
    result = document.execCommand('copy')
  } catch (err) {
    result = false
  }

  document.body.removeChild(textArea)
  return result
}
