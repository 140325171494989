import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  ENTITY,
  NAMINGS,
  SHARE_DATA_TYPES,
  WEBSITE,
  API_GET_SCREENSHOT,
} from 'src/consts'
import { getCurrentWatchListItems } from 'src/redux/selectors'

const getShortReadablePageName = (location: string) => {
  if (location === '/coin/') return 'Coin360 cryptocurrencies'
  if (/\/coin.*/.test(location)) return 'Coin'
  if (location === '/exchange/table') return 'Coin360 crypto exchanges'
  if (/\/exchange.*/.test(location)) return 'Exchange'
  if (/\/news.*/.test(location)) return 'Blog'
  if (/\/liquidity-book.*/.test(location)) return 'Liquidity Book'
  if (/\/press-releases.*/.test(location)) return 'Press Releases'
  if (/\/event.*/.test(location)) return 'Events'
  if (/\/watchlist.*/.test(location)) return 'Watchlist'
  if (/\/charts.*/.test(location)) return 'Charts'
  if (/\/advertising.*/.test(location)) return 'Advertise with Coin360.com'
  if (/\/faq.*/.test(location)) return 'Frequently Asked Questions'
  if (/\/about.*/.test(location)) {
    return (
      {
        '/about/generalinfo': 'General Info',
        '/about/methodology': 'Methodology',
        '/about/liquidity-book': 'Liquidity Book',
        '/about/widgets': 'COIN360 widgets',
        '/about/changelog': 'Changelog',
        '/about/privacy': 'Privacy Policy',
      }[location] || 'About Page'
    )
  }
  return 'Share Page'
}

type useShareDialogParams = {
  location: string
  shareType: ValueOf<typeof SHARE_DATA_TYPES>
  entity?: ValueOf<typeof ENTITY>
}

export function useShareDialog({
  entity,
  location,
  shareType,
}: useShareDialogParams) {
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  const currentWatchlist = useSelector(getCurrentWatchListItems)

  const seo = useMemo(() => {
    return {
      title: document.title,
      description: (
        document.querySelector('meta[name="description"]') as HTMLMetaElement
      ).content,
      location,
    }
  }, [location])

  const isTreeMap = shareType === SHARE_DATA_TYPES.MAP

  useEffect(() => {
    if (isTreeMap) {
      // const route = API_ROUTES.getScreenShot
      // const nonMapPage = !['watchlist', 'exchange', ''].includes(location.split('/')[1])

      // const routeWithUrl = {
      //   ...route,
      //   search: {
      //     path: route.search.url + `${location.replace('/coin/', '')}`
      //   }
      // }

      // if (nonMapPage) routeWithUrl.search.url = '/'

      const filters = Object.fromEntries(
        new URLSearchParams(window.location.search)
      )
      const fetchParams: { [key: string]: string } = {
        // path: routeWithUrl.search.path
        getScreen: '',
        ...filters,
      }

      if (location.includes('/watchlist'))
        fetchParams.watch = `[${currentWatchlist.toString()}]`
      setLoading(true)
      getScreenShot(window.location.pathname, fetchParams)
        .then(setResult)
        .finally(() => setLoading(false))
    }
  }, [location, isTreeMap, currentWatchlist])

  let title = getShortReadablePageName(location)

  if (isTreeMap) {
    const t = {
      [ENTITY.COIN]: `Take a look at the state of the cryptocurrency market on the ${NAMINGS.socialsNickname} heat map!`,
      [ENTITY.EXCHANGE]: `Real-time crypto exchange heat map provided by ${NAMINGS.socialsNickname}`,
    }[entity]
    if (t) title = t
  }

  return {
    shareData: {
      description: seo.description,
      keyWords: 'crypto cryptocurrency coin',
      time: new Date(),
      title,
      url: WEBSITE,
    },
    linkShare: isTreeMap ? result?.url || '' : `${WEBSITE}${location}`,
    linkImg: isTreeMap
      ? result?.url || ''
      : `${WEBSITE}/assets/share/index.png`,
    loading,
  }
}

function getScreenShot(path, fetchParams: { [key: string]: string }) {
  const search = new URLSearchParams({
    zoom: JSON.stringify({ x: 0, y: 0, k: 1 }),
    ...fetchParams,
  }).toString()
  const params = new URLSearchParams({
    height: String(window.innerHeight),
    width: String(window.innerWidth),
    path,
    search,
  }).toString()

  return fetch(`${API_GET_SCREENSHOT}/?${params}`, {
    headers: {
      'User-Agent': window.navigator.userAgent,
    },
  }).then(res => res.json())
}
